.box-online {
  width: 35%;
  height: 190px;
  border-radius: 6px;
  background: white;
  padding: 16px 0px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 8px;
}

.box-feedback {
  // height: 256px;
  width: 90% !important;
  border-radius: 6px;
  background: #E5E5E5;
  padding: 16px 12px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
  margin-top: 8px;
}

.images-feedback {
  width: 40px;
  height: 40px;
  object-fit: contain;
  margin-right: 8px;
}

.images-items {
  width: 70px;
  height: 75px;
  object-fit: contain;
}

.custom-slick {
  .slick-slide {
    cursor: pointer;
    //   >div {
    //     &:first-child {}
    //   }
  }

  .slick-track {
    width: 100% !important;
    display: flex;
    justify-content: space-between;
  }
}

.slick-dots {
  bottom: 24px;

  li {
    button:before {
      font-size: 16px;
    }
  }
}

.slick-dots li.slick-active button:before {
  font-size: 22px !important;
}

.list-child {
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  display: grid;
  width: 100%;
  gap: 20px;
}

.list-child-tablet {
  grid-template-columns: repeat(2, 1fr);
  display: grid;
  gap: 12px;
}